import React from 'react';
import '../App.css';

const about_me_page = (props) => {

    return (
        <div>
            <p>This is the about me page</p>
        </div>
    )
}

export default about_me_page;